import { Avatar } from '../avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../sidebar'
import { SidebarLayout } from '../sidebar-layout'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/16/solid'
import {
  ClipboardDocumentCheckIcon,
  CogIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/24/solid';
import {Outlet} from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { useOne } from '@refinedev/core';
import { useEffect, useState } from 'react';
import Loading from '../loading/loading';
import { Heading } from '../heading';

const Layout = () => {
  const { user: auth0User, logout } = useAuth0();
  const [user, setUser] = useState<VhUser | null>(null);
  const [facility, setFacility] = useState<Facility | null>(null);

  const { data: findMeData, isLoading } = useOne({
    resource: 'people',
    id: 'me',
  });

  useEffect(() => {
    if (findMeData) {
      setUser(findMeData?.data[1]);
      setFacility(findMeData?.data[0]);
    }
  }, [auth0User, findMeData, setUser, setFacility]);


  return (isLoading ? <Loading /> :
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            {/*<NavbarItem href="/search" aria-label="Search">*/}
            {/*  <MagnifyingGlassIcon />*/}
            {/*</NavbarItem>*/}
            {/*<NavbarItem href="/inbox" aria-label="Inbox">*/}
            {/*  <BellAlertIcon />*/}
            {/*</NavbarItem>*/}
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src="https://i.pravatar.cc/150?img=52" square />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                {/*<DropdownItem href="/settings">*/}
                {/*  <Cog8ToothIcon />*/}
                {/*  <DropdownLabel>Settings</DropdownLabel>*/}
                {/*</DropdownItem>*/}
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign Out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <div className="flex flex-row items-center">
              <Avatar className="mb-2 size-12 bg-gray-800 p-1" square={true} src="/logo-yellow.png" />
              <Heading className='mb-2 ml-4' level={4}>VitalHive</Heading>
            </div>
            {/*<Dropdown>*/}
            {/*  <DropdownButton as={SidebarItem} className="lg:mb-2.5">*/}
            {/*    <Avatar square={true} slot="icon" initials={facility?.facility_name.split(' ').slice(0, 2).map(word => word[0]).join('')}  />*/}
            {/*    <SidebarLabel>{facility?.facility_name}</SidebarLabel>*/}
            {/*    <ChevronDownIcon />*/}
            {/*  </DropdownButton>*/}
            {/*  <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">*/}
            {/*    <DropdownItem>*/}
            {/*      <Avatar square={true} slot="icon" initials={facility?.facility_name.split(' ').slice(0, 2).map(word => word[0]).join('')}  />*/}
            {/*      <DropdownLabel>{facility?.facility_name}</DropdownLabel>*/}
            {/*    </DropdownItem>*/}
            {/*    <DropdownDivider />*/}
            {/*    <DropdownItem>*/}
            {/*      <CogIcon />*/}
            {/*      <DropdownLabel>Settings</DropdownLabel>*/}
            {/*    </DropdownItem>*/}
            {/*  </DropdownMenu>*/}
            {/*</Dropdown>*/}
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarItem href="/dashboard">
                <PresentationChartLineIcon />
                <SidebarLabel>Dashboard</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/shift-management">
                <ClipboardDocumentCheckIcon />
                <SidebarLabel>Shift Management</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
            <SidebarSpacer />
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src={auth0User?.picture} className="size-10" square alt="" />
                  <span className="min-w-0">
                  <span
                    className="dark:text-white block truncate text-sm/5 font-medium text-zinc-950">{`${user?.people.firstname} ${user?.people.lastname}`}</span>
                    <span className="dark:text-zinc-400 block truncate text-xs/5 font-normal text-zinc-500">
                      {user?.people.email ?? user?.people.phone}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownDivider />
                <DropdownItem onClick={() => logout()}>
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign Out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      <Outlet />
    </SidebarLayout>
  )
}

export default Layout;