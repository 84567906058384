import React from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import classNames from "../shared/utils/class-names";

type Props = {
  reliability: number;
  showNumber?: boolean;
};

const ReliabilityRank: React.FC<Props> = ({ reliability, showNumber = true }) => {
  return (
    <div className="flex items-center xl:col-span-1">
      <div className="flex items-center">
        {[0, 1, 2, 3, 4].map((rating) => (
          <div key={rating} className="relative">
            <StarIcon
              aria-hidden="true"
              className={classNames(
                'text-gray-200',
                'h-5 w-5 flex-shrink-0',
              )}
            />
            <div
              className="absolute left-0 top-0 h-full overflow-hidden"
              style={{ width: `${Math.min(Math.max(reliability - rating, 0), 1) * 100}%` }}
            >
              <StarIcon
                aria-hidden="true"
                className="size-5 shrink-0 text-yellow-500"
              />
            </div>
          </div>
        ))}
      </div>
      { showNumber && <p className="ml-3 text-sm text-gray-700">
        {Number(reliability / 20).toFixed(1)}
        <span className="sr-only"> out of 5 stars</span>
      </p>
      }
    </div>
  );
};

export default ReliabilityRank;