import React, { useEffect, useState } from 'react';
import { Button, Drawer, Label, Select, Textarea, TextInput } from 'flowbite-react';
import { useForm } from '@refinedev/react-hook-form';
import { useList } from '@refinedev/core';
import { HttpError } from '@refinedev/core';
import Timepicker from '../../components/timepicker';
import BreakTimeDropdown from '../../components/forms/break-select';
import { Calendar, DateObject } from "react-multi-date-picker";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type ShiftDrawerProps = {
  open: boolean;
  setOpen: (toggle: boolean) => void;
  refetchData?: () => void;
};
const ShiftDrawer = ({ open, setOpen, refetchData }: ShiftDrawerProps) => {
  const { refineCore: { onFinish }, register, handleSubmit, reset, setValue, formState: { errors }} = useForm<Shift>({
    refineCoreProps: {
      resource: "shifts",
      action: "create",
      onMutationSuccess: () => {
        refetchData && refetchData();
        setOpen(false);
        reset();
      },
  }});

  const [validForm, setValidForm] = useState<boolean>(false);

  const { data: positions } = useList<Position, HttpError>({
    resource: "positions",
  });

  useEffect(() => {
    setValue('timezone', dayjs.tz.guess());
  }, [setValue]);

  const MIN_DATE = new Date();
  MIN_DATE.setDate(MIN_DATE.getDate());

  const handleDateChange = (args: DateObject[]) => {
    const stringDates = args.map((arg) => {
      return dayjs(arg).tz(dayjs.tz.guess()).toISOString();
    })

    setValidForm(stringDates.length > 0);
    setValue('dates', stringDates);
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      position='right'
      className='md:w-[400px]'
    >
      <Drawer.Header title='Shift Details'></Drawer.Header>
      <Drawer.Items>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onFinish)}>
          <div className="mx-auto">
              <Calendar
                // value={selectedDay}
                multiple
                className="shadow-none"
                onChange={handleDateChange}
                minDate={MIN_DATE}
              />
          </div>

          <Timepicker register={register} setValidForm={setValidForm} />
          <BreakTimeDropdown register={register} />

          {positions?.data && (
            <div>
            <Label>Position</Label>
              <Select id="position" {...register('position_id', { required: true })}>
                <option key="0" value="" />
                {positions?.data?.map((pos) => (
                  <option key={pos.position_id} value={pos.position_id}>
                    {pos.position_name}
                  </option>
                ))}
              </Select>
            </div>
          )}

          <div>
            <Label>Description</Label>
            <Textarea
              required
              className="h-24 w-full"
              placeholder="Description"
              {...register('description', { required: true })}
            />
          </div>

          <div>
            <Label>Instructions</Label>
            <Textarea
              required
              className="h-24 w-full"
              placeholder="Instructions"
              {...register('instructions', { required: true })}
            />
          </div>

          <div>
            <Label>Number of Openings</Label>
            <TextInput
              required
              className="h-24"
              placeholder="1"
              type="number"
              {...register('number_of_openings', { required: true, valueAsNumber: true })}
            />
          </div>

          <div className="flex flex-row justify-between">
            <Button color='blue' onClick={() => setOpen(false)} outline>
              Cancel
            </Button>
            <Button color='blue' type="submit" disabled={!validForm}>
              Post
            </Button>
          </div>
        </form>
      </Drawer.Items>
    </Drawer>
  );
};

export default ShiftDrawer;