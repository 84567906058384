import React from 'react';
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { useAuth0 } from "@auth0/auth0-react";
import nestjsxCrudDataProvider, { axiosInstance } from "@refinedev/nestjsx-crud";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import "./App.css";
import Layout from "./components/layout";
import { Login } from "./pages/login";
import Dashboard from "./pages/dashboard";
import CalendarMonthView from "./pages/shift-management/calendar-month-view";
import CalendarDayView from "./pages/shift-management/calendar-day-view";
import Loading from "./components/loading/loading";
import MyProfile from './pages/my-profile';
import { Refine, AuthProvider, Authenticated, ErrorComponent } from "@refinedev/core";
import routerProvider, {
  NavigateToResource, UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';

function App () {
  const { isLoading, isAuthenticated, user, logout, getIdTokenClaims } = useAuth0();
// Setup axios to include the token in every request
  const axios = axiosInstance;
  const API_URL = import.meta.env.VITE_API_URL;
  const dataProvider = nestjsxCrudDataProvider(API_URL, axios);

  if (isLoading) {
    return <Loading />;
  }

  const authProvider: AuthProvider = {
    login: async () => {
      return {
        success: true,
      };
    },
    logout: async () => {
      logout({ returnTo: window.location.origin });
      return {
        success: true,
      };
    },
    onError: async (error) => {
      console.error(error);
      return { error };
    },
    check: async () => {
      try {
        const token = await getIdTokenClaims();
        if (token) {
          axios.defaults.headers.common = {
            Authorization: `Bearer ${token.__raw}`,
          };
          return {
            authenticated: true,
          };
        } else {
          return {
            authenticated: false,
            error: {
              message: "Check failed",
              name: "Token not found",
            },
            redirectTo: "/login",
            logout: true,
          };
        }
      } catch (error: any) {
        return {
          authenticated: false,
          error: new Error(error),
          redirectTo: "/login",
          logout: true,
        };
      }
    },
    getPermissions: async () => null,
    getIdentity: async () => {
      console.log('identity?')
      if (user) {
        return {
          ...user,
          avatar: user.picture,
        };
      }
      return null;
    },
  };

  getIdTokenClaims().then((token) => {
    if (token) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token.__raw}`,
      };
    }
  });

  return (
    <BrowserRouter>
        <RefineKbarProvider>
          <Refine
            dataProvider={dataProvider}
            routerProvider={routerProvider}
            authProvider={authProvider}
            resources={[
              {
                name: "dashboard",
              },
              {
                name: "shift-management",
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              useNewQueryKeys: true,
              projectId: "SXTD1H-As6Lek-fqTINw",
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated
                    // v3LegacyAuthProviderCompatible
                    key="authenticated-routes"
                    fallback={<Navigate to="/login" />}
                  >
                    <Layout />
                  </Authenticated>
                }
              >
                <Route path="/shift-management">
                  <Route index element={<CalendarMonthView />} />
                  <Route path="day/:date" element={<CalendarDayView />} />
                </Route>
                <Route path="/dashboard">
                  <Route index element={<Dashboard />} />
                </Route>
                <Route path="/my-profile">
                  <Route index element={<MyProfile />} />
                </Route>
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Route>
              <Route
                element={
                  <Authenticated key="auth-pages" fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
              </Route>

              <Route
                element={
                  <Authenticated key="catch-all">
                    <Layout />
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
            {/*<DocumentTitleHandler />*/}
          </Refine>
        </RefineKbarProvider>
    </BrowserRouter>
);
}

export default App;
