import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs'

dayjs.extend(utc);
dayjs.extend(timezone);

const formatTime = (time: string): string => {
  return dayjs.utc(time).format('hh:mm A');
};

const setColor = (opening: Opening, applications: Application[]) => {
  const { status } =  opening;
  if (status === 'CANCELLED') {
    return '#cc0000';
  } else if (opening.assigned_clinician && status === 'SELECTED') {
    return '#ffff4d';
  } else if (opening.assigned_clinician && status === 'CONFIRMED') {
    return '#80ccff';
  } else if (opening.status === 'COMPLETED' || opening.status === 'CONFIRMED') {
    return '#009933';
  } else if (applications.length > 0 && status === 'ACTIVE') {
    return 'orange';
  } else {
    return '#9494b8';
  }
};

const generateEvents = (shifts: Shift[]): any[] => {
  return shifts.flatMap((shift) => shift.openings.map<any>((opening) => {
    const parsedStart = formatTime(opening.start_time);
    const parsedEnd = formatTime(opening.end_time);

    return {
      id: opening.opening_id,
      start: new Date(shift.start_time),
      end: new Date(shift.end_time),
      allDay: true,
      title: `${shift.positions.position_name}: ${parsedStart} - ${parsedEnd}`,
      name: `${shift.positions.position_name}: ${parsedStart} - ${parsedEnd}`,
      color: setColor(opening, shift.applications),
      customData: shift,
      status: opening?.status,
      applicationsToReview: shift.applications.filter((a) => a.status === 'PENDING').length,
      assignedClinician: opening?.clinicians,
      position: shift.positions.position_name,
      parsedStart,
      parsedEnd,

    };
  }));
};

export default generateEvents;