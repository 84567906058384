import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Login: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogin = async () => {
      console.log('isAuthenticated', isAuthenticated)
      if (!isAuthenticated) {
        console.log('will we login')
        await loginWithRedirect();
      }
    };
    handleLogin()
  }, [isAuthenticated, loginWithRedirect, navigate]);

  return <></>;
};