import React, { useState, useEffect } from 'react';
import ShiftDrawer from './shift-drawer';
import { HttpError, useList } from '@refinedev/core';
import Loading from '../../components/loading/loading';
import generateEvents from '../../shared/utils/generate-events';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ShiftManagementHeader from './shift-management-header';
import { Calendar as BigCalendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Text } from '../../components/text';
import { Avatar } from 'flowbite-react';
import ReliabilityRank from '../../components/reliability-rank';
import getStatusColor from '../../shared/utils/get-status-color';
import { Badge } from '../../components/badge';
import { ClockIcon } from '@heroicons/react/24/solid';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York');
const CalendarMonthView = () => {
  const navigate = useNavigate();
  const [openShiftDrawer, setOpenShiftDrawer] = useState<boolean>(false);
  const [eventData, setEventData] = useState<any[]>([]);
  const { data: shifts, isLoading } = useList<Shift, HttpError>({
    resource: "shifts",
  });

  const localizer = dayjsLocalizer(dayjs)
  useEffect(() => {
    if (shifts) {
      const events = generateEvents(shifts.data);
      setEventData(events);
      console.log(events)
    }
  }, [shifts]);

  const navigateToEditView = (event: any) => {
    const date = dayjs(event.customData.date).utc().format('YYYY-MM-DD');
    navigate({
      pathname: `/shift-management/day/${date}`,
    });
  };

  const eventPropGetter = (event: any) => {
    return {
      style: {
        marginTop: '5px',
      },
    };
  };

  return isLoading ? <Loading />
    : <div className="flex flex-col">
      <ShiftManagementHeader setOpenShiftDrawer={setOpenShiftDrawer} />
      <BigCalendar
        defaultView={'week'}
        views={['week']}
        localizer={localizer}
        events={eventData}
        onSelectEvent={navigateToEditView}
        style={{ height: "100vh" }}
        step={60}
        timeslots={1}
        dayLayoutAlgorithm={'no-overlap'}
        eventPropGetter={eventPropGetter}
        components={{
          event: (event) => {
            const eventStyles = {
              background: 'white',
              minHeight: '75px',
              maxHeight: '150px',
              color: 'black',
              padding: '10px',
              border: '1px solid #d4d4d4',
              borderRadius: '5px',
              borderLeft: `5px solid ${event.event.color}`,
              hover: {
                cursor: 'pointer',
                backgroundColor: '#d4d4d4',
              },
            }

            console.log(event.event.assignedClinician);
            console.log(event.event.applicationsToReview);
            const shift = event.event;
            const clinician = shift?.assignedClinician;
            const status = event.event.status

            return (
              <div className="flex flex-col" style={eventStyles}>
                <div className='flex justify-between'>
                  <Text className="font-bold">{event.event.position}</Text>
                  <Badge color={getStatusColor(status)}>{status}</Badge>
                </div>
                <div className='flex'>
                  <ClockIcon width="20px" color='gray' className='mr-1' />
                  <Text>{`${shift.parsedStart} - ${shift.parsedEnd}`}</Text>
                </div>
                { shift.applicationsToReview > 0 && <Text>{`${shift.applicationsToReview}`} pending application(s)</Text> }
                {
                  clinician && <div className='flex content-center justify-items-start'>
                    <Avatar size={'md'} color='info' status={status === 'CONFIRMED' ? 'online' : 'away' } placeholderInitials={`${clinician?.people.firstname[0]}${clinician.people.lastname[0]}`} />
                    <div className='ml-2 flex flex-col'>
                      <Text className='font-bold'>{clinician?.people?.firstname} {clinician?.people?.lastname}</Text>
                      <ReliabilityRank reliability={clinician?.reliability} showNumber={false} />
                    </div>
                  </div>
                }
              </div>
            );
        }
      }}

      />
      <ShiftDrawer open={openShiftDrawer} setOpen={setOpenShiftDrawer} />
    </div>
};

export default CalendarMonthView;

